import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router, Route } from "./router.js";
import { unregister } from "./serviceWorker";
import "./assets/css/style.scss";
import { store } from "./store";

const createWithDefaultProps = (Component, props) => <Component {...props} />;

require('typeface-roboto-condensed');

const App = React.lazy(() =>
    new Promise((resolve) =>
        setTimeout(() => resolve(import("./App/App.js")), 200)
    )
);

const baseName =
    process.env.PUBLIC_URL === "./" || process.env.PUBLIC_URL === "."
        ? ""
        : process.env.PUBLIC_URL;

const rootElement = document.getElementById('finforms-app')

ReactDOM.render(
    <Suspense fallback={<div className="loading"><div className="lds-ring">
        <div/>
        <div/>
        <div/>
        <div/>
    </div></div>}>
    <I18nextProvider i18n={i18n} t={i18n.t}>
        <Provider store={store} key="provider">
            <Router createElement={createWithDefaultProps} basename={baseName}>
                <Route path="/" component={App} />
            </Router>
        </Provider>
    </I18nextProvider></Suspense>,
    rootElement
);
unregister();
