import { version } from "../../package.json";
import { createSelector } from "reselect";
import * as api from "../services/api";

const PREFIX = version + "_";
const SET_TOAST = `${PREFIX}SET_TOAST`;
const SET_USERS = `${PREFIX}SET_USERS`;
const SET_USER = `${PREFIX}SET_USER`;
const SET_SETTINGS = `${PREFIX}SET_SETTINGS`;
const SET_USER_SETTINGS = `${PREFIX}SET_USER_SETTINGS`;
const SET_DIVISIONS = `${PREFIX}SET_DIVISIONS`;
const SET_DIVISION = `${PREFIX}SET_DIVISION`;
const SET_USER_REQUESTS = `${PREFIX}SET_USER_REQUESTS`;
const SET_ALL_REQUESTS = `${PREFIX}SET_ALL_REQUESTS`;
const SET_UNREAD_MESSAGES = `${PREFIX}SET_UNREAD_MESSAGES`;
const SET_TRANSLATION = `${PREFIX}SET_TRANSLATION`;
const SET_TRANSLATIONS = `${PREFIX}SET_TRANSLATIONS`;
const SET_LANGUAGES = `${PREFIX}SET_LANGUAGES`;
const SET_LANGUAGE = `${PREFIX}SET_LANGUAGE`;
const SET_REQUEST = `${PREFIX}SET_REQUEST`;
const SET_CURRENT_TAB = `${PREFIX}SET_CURRENT_TAB`;
const SET_FORMS = `${PREFIX}SET_FORMS`;
const SET_FORM = `${PREFIX}SET_FORM`;

export const getReducerName = () => PREFIX + "mainReducer";
export const getMainReducerState = state => state[getReducerName()];

export const initialState = {
    users: [],
    toast: false,
    settings: {},
    userSettings: {},
    userRequests: [],
    allRequests:[],
    unreadMessages: [],
    divisions: [],
    division: false,
    language: "de",
    translation: [],
    translations: [],
	currentTab: 0,
	forms: [],
	form: false,
	request: false,
	user: false
};

export const getUser = createSelector(
    getMainReducerState,
    mainReducer => mainReducer.user
);
export const getUsers = createSelector(
    getMainReducerState,
    mainReducer => mainReducer.users
);
export const getForms = createSelector(
    getMainReducerState,
    mainReducer => mainReducer.forms
);
export const getForm = createSelector(
    getMainReducerState,
    mainReducer => mainReducer.form
);
export const getCurrentTab = createSelector(
    getMainReducerState,
    mainReducer => mainReducer.currentTab
);
export const getUserSettings = createSelector(
    getMainReducerState,
    mainReducer => mainReducer.userSettings
);
export const getSettings = createSelector(
    getMainReducerState,
    mainReducer => mainReducer.settings
);
export const getToast = createSelector(
    getMainReducerState,
    mainReducer => mainReducer.toast
);
export const getUserRequests = createSelector(
    getMainReducerState,
    mainReducer => mainReducer.userRequests
);
export const getAllRequests = createSelector(
    getMainReducerState,
    mainReducer => mainReducer.allRequests
);
export const getDivisions = createSelector(
    getMainReducerState,
    mainReducer => mainReducer.divisions
);
export const getDivision = createSelector(
    getMainReducerState,
    mainReducer => mainReducer.division
);
export const getUnreadMessages = createSelector(
    getMainReducerState,
    mainReducer => mainReducer.unreadMessages
);
export const getTranslation = createSelector(
    getMainReducerState,
    mainReducer => mainReducer.translation
);
export const getTranslations = createSelector(
    getMainReducerState,
    mainReducer => mainReducer.translations
);
export const getLanguages = createSelector(
    getMainReducerState,
    mainReducer => mainReducer.languages
);
export const getLanguage = createSelector(
    getMainReducerState,
    mainReducer => mainReducer.language
);
export const getRequest = createSelector(
    getMainReducerState,
    mainReducer => mainReducer.request
);

export function setUser(user) {
    return {
        type: SET_USER,
        user
    };
}
export function setUsers(users) {
    return {
        type: SET_USERS,
        users
    };
}
export function setForms(forms) {
    return {
        type: SET_FORMS,
        forms
    };
}
export function setForm(form) {
    return {
        type: SET_FORM,
        form
    };
}
export function setCurrentTab(currentTab) {
    return {
        type: SET_CURRENT_TAB,
        currentTab
    };
}
export function setToast(toast) {
    return {
        type: SET_TOAST,
        toast
    };
}
export function setUserSettings(userSettings) {
    return {
        type: SET_USER_SETTINGS,
        userSettings
    };
}
export function setDivisions(divisions) {
    return {
        type: SET_DIVISIONS,
        divisions
    };
}
export function setDivision(division) {
    return {
        type: SET_DIVISION,
        division
    };
}
export function setSettings(settings) {
    return {
        type: SET_SETTINGS,
        settings
    };
}
export function setUserRequests(userRequests) {
    return {
        type: SET_USER_REQUESTS,
        userRequests
    };
}
export function setAllRequests(allRequests) {
    return {
        type: SET_ALL_REQUESTS,
        allRequests
    };
}
export function setUnreadMessages(unreadMessages) {
    return {
        type: SET_UNREAD_MESSAGES,
        unreadMessages
    };
}
export function setTranslations(translations) {
    return {
        type: SET_TRANSLATIONS,
        translations
    };
}
export function setTranslation(translation) {
    return {
        type: SET_TRANSLATION,
        translation
    };
}
export function setLanguages(languages) {
    return {
        type: SET_LANGUAGES,
        languages
    };
}
export function setLanguage(language) {
    return {
        type: SET_LANGUAGE,
        language
    };
}
export function setRequest(request) {
    return {
        type: SET_REQUEST,
        request
    };
}

export function fetchLanguages() {
    return (dispatch) => {
        api.PostData('getLanguages', {}).then((result) => {
            if(result.languages){
                dispatch(setLanguages(result.languages))
            }
        });
    }
}
export function fetchTranslation(language) {
    return (dispatch) => {
        api.PostData('getTranslation', {
            language: language
        }).then((result) => {
            if(result.translation){
                dispatch(setTranslation(result.translation));
                dispatch(setLanguage(language));
            }
        });
    }
}
export function fetchTranslations(userId, token) {
    return (dispatch) => {
        api.PostData('getTranslations', {
            user_id: userId,
            token: token
        }).then((result) => {
            if(result.translations){
                dispatch(setTranslations(result.translations));
            }
        });
    }
}
export function updateTranslations(userId, token, data) {
    return (dispatch) => {
        api.PostData('setTranslations', {
            user_id: userId,
            token: token,
            translations: data
        }).then((result) => {
            // if(result.divisions){
				dispatch(setToast(result.response));
                dispatch(setTranslation(result.translation))
            // }
        });
    }
}
export function fetchDivision(division) {
    return (dispatch) => {
        return api.PostData('getDivision', {
            division: division,
        }).then((result) => {
            if(result.division){
            	if(result.division.settings){
            		result.division.settings = JSON.parse(result.division.settings);
				}
            	dispatch(setDivision(result.division))
            }
            if(result.forms){
            	dispatch(setForms(result.forms))
            }
        });
    }
}
export function fetchDivisions(userId, token, user = false) {
    return (dispatch) => {
        api.PostData('getDivisions', {
            user_id: userId,
            token: token,
            user: user
        }).then((result) => {
            if(result.divisions){
				const divisions = result.divisions.map(d => {
					const settings = d.settings ? JSON.parse(d.settings) : "";
					return {
						...d,
						settings: settings
					}
				})
                dispatch(setDivisions(divisions))
            }
        });
    }
}
export function fetchSettings(userId, token, user = false) {
    return (dispatch) => {
        api.PostData('getSettings', {
            user_id: userId,
            token: token,
            user: user
        }).then((result) => {
            if(result.settings){
                if(user){
                    dispatch(setUserSettings(JSON.parse(result.settings)))
                }else{
                    dispatch(setSettings(JSON.parse(result.settings)))
                }
            }
        });
    }
}
export function updateSettings(data, userId, token, user) {
    return (dispatch) => {
        api.PostData('setSettings', {
            settings: JSON.stringify(data),
            user_id: userId,
            token: token,
            user: user
        }).then((result) => {
            dispatch(setToast(result));
        });
    }
}
export function fetchUsers(userId, token) {
    return (dispatch) => {
        api.PostData('users', {
            user_id: userId,
            token: token
        }).then((result) => {
            if(result.users){
                dispatch(setUsers(result.users))
            }
        });
    }
}
export function saveRequest(email, password, request, files, state, id, userId, division){
    return (dispatch) => {
        return api.PostData('saveRequest', {
            email: email,
            password: password,
            request: JSON.stringify(request),
            id: id,
            state: state,
            user_id: userId,
			division: division
        }).then((result) => {
			dispatch(setToast(result));
			if(result.id){
				api.PostData('uploadFiles', {}, files, result.id).then(result => {
				
				});
			}
			return true;
        });
    }
}
export function loadUserRequest(email, password, userId, division){
    return (dispatch) => {
        return api.PostData('getRequest', {
            email: email, password: password, user_id: userId, division: division
        }).then((result) => {
            if(result.requests){
                dispatch(setUserRequests(result.requests))
            }
            return true;
        });
    }
}
export function loadAllRequests(userId, token){
    return (dispatch) => {
        api.PostData('getRequests', {
            user_id: userId,
            token: token
        }).then((result) => {
            if(result.requests){
                dispatch(setAllRequests(result.requests))
            }
        });
    }
}

export function setRequestState(userId, token, state, id){
    return (dispatch) => {
        api.PostData('setRequestState', {
            user_id: userId,
            token: token,
            state: state,
            id: id
        }).then((result) => {
            if(result.requests){
                dispatch(setAllRequests(result.requests))
            }
        });
    }
}
export function deleteRequest(userId, token, id){
    return (dispatch) => {
        api.PostData('deleteRequest', {
            user_id: userId,
            token: token,
            id: id
        }).then((result) => {
            if(result.requests){
                dispatch(setAllRequests(result.requests))
				dispatch(setToast(result.response));
            }
        });
    }
}
export function archiveRequest(userId, token, archive, id){
    return (dispatch) => {
        api.PostData('archiveRequest', {
            user_id: userId,
            token: token,
            archive: archive,
            id: id
        }).then((result) => {
            if(result.requests){
                dispatch(setAllRequests(result.requests))
            }
        });
    }
}
export function updateAvatar(userId, token, avatar, user){
    return (dispatch) => {
        api.PostData('updateAvatar', {
            user_id: userId,
            token: token,
            avatar: avatar,
            user: user
        }).then((result) => {
            if(result.users){
                dispatch(setUsers(result.users))
				dispatch(setToast(result.response));
            }
        });
    }
}
export function fetchUnreadMessages(userId, token){
    return (dispatch) => {
        api.PostData('unreadMessages', {
            user_id: userId,
            token: token
        }).then((result) => {
            if(result.unreadMessages){
                dispatch(setUnreadMessages(result.unreadMessages))
            }
        });
    }
}
export function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.user
            };
        case SET_USERS:
            return {
                ...state,
                users: action.users
            };
        case SET_TOAST:
            return {
                ...state,
                toast: action.toast
            };
        case SET_SETTINGS:
            return {
                ...state,
                settings: action.settings
            };
        case SET_USER_SETTINGS:
            return {
                ...state,
                userSettings: action.userSettings
            };
        case SET_DIVISIONS:
            return {
                ...state,
                divisions: action.divisions
            };
        case SET_DIVISION:
            return {
                ...state,
                division: action.division
            };
        case SET_USER_REQUESTS:
            return {
                ...state,
                userRequests: action.userRequests
            };
        case SET_ALL_REQUESTS:
            return {
                ...state,
                allRequests: action.allRequests
            };
        case SET_UNREAD_MESSAGES:
            return {
                ...state,
                unreadMessages: action.unreadMessages
            };
        case SET_TRANSLATION:
            return {
                ...state,
                translation: action.translation
            };
        case SET_TRANSLATIONS:
            return {
                ...state,
                translations: action.translations
            };
        case SET_LANGUAGES:
            return {
                ...state,
                languages: action.languages
            };
        case SET_LANGUAGE:
            return {
                ...state,
                language: action.language
            };
        case SET_REQUEST:
            return {
                ...state,
                request: action.request
            };
        case SET_CURRENT_TAB:
            return {
                ...state,
                currentTab: action.currentTab
            };
        case SET_FORMS:
            return {
                ...state,
                forms: action.forms
            };
        case SET_FORM:
            return {
                ...state,
                form: action.form
            };
        default:
            return state;
    }
}
